import { onErrorCaptured } from 'vue';
import store from '../store';
import axios from 'axios';
import DeviceDetector from "device-detector-js";

axios.defaults.withCredentials = true;
// axios.defaults.credentials = "include";

// import crypto from 'crypto-browserify';
// const hash = crypto.createHash('sha512');
const CryptoJS = require("crypto-js");

class AuthService {
    login(user, recaptcha_response) {
        return new Promise(async (resolve, reject) => {
            try {
                const data = {
                    email: user.email,
                    password: user.password,
                    recaptcha_response: recaptcha_response,
                    keep_login: user.keep_login,
                    device: this.getDeviceInfo(),
                    network: await this.getNetworkInfo(),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }

                axios.post(store.state.api_url + 'auth/signin', data).then(response => {
                    if (response.data.user) {
                        store.commit("setUser", response.data.user);
                    }

                    resolve(true);
                }).catch(error => {
                    reject(error);
                })
            } catch (error) {
                console.log(error)
                reject(error)
            }
        });
    }

    logout() {
        store.commit("setUser", null);
    }

    register(user, recaptcha_response) {
        var data = {
            email: user.email,
            password: user.password,
            title: user.title,
            name: user.name,
            middlename: user.middlename,
            lastname: user.lastname,
            gender: user.gender,
            birthdate: user.birthdate,
            phone: user.phone.replace(/\D/g, ""),
            country: user.country,
            city: user.city,
            address: user.address,
            postal_code: user.postal_code,
            state: user.state,
            referral_code: user.referral_code,
            recaptcha_response: recaptcha_response,
            headers: {
                'Content-Type': 'application/json'
            },
            utm: user.utm
        };

        return axios.post(store.state.api_url + 'auth/signup', data);
    }

    resetPassword(email, recaptcha_response) {
        return axios.post(store.state.api_url + 'auth/reset-password', {
            email: email,
            recaptcha_response: recaptcha_response,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    refreshPassword(token, new_password, new_password2, recaptcha_response) {
        return axios.post(store.state.api_url + 'auth/refresh-password', {
            token: token,
            new_password: new_password,
            new_password2: new_password2,
            recaptcha_response: recaptcha_response,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    checkAccessToken() {
        return new Promise((resolve, reject) => {
            axios.post(store.state.api_url + 'auth/verify', {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-user-id': store.state.user.id,
                    'x-user-mail': store.state.user.email
                }
            }).then(response => {
                resolve(true);
            }).catch(error => {
                console.log(error)
                resolve(false)
            })
        });
    }

    refreshAccessToken() {
        return new Promise((resolve, reject) => {
            axios.post(store.state.api_url + 'auth/refresh', {
                email: store.state.user.email
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-user-id': store.state.user.id
                }
            }).then(response => {
                resolve(true);
            }).catch(error => {
                console.log(error)
                resolve(false)
            })
        });
    }

    verify(to, from, next) {
        if (store.state.user == null || store.state.user == undefined) {
            this.logout();
            to.meta.layout = 'auth';
            next('/login');
        } else {
            this.checkAccessToken().then(response => {
                if (response) {
                    to.meta.layout = 'app';
                    next();
                } else {
                    this.refreshAccessToken().then(response => {
                        if (response) {
                            to.meta.layout = 'app';
                            next();
                        } else {
                            this.logout();
                            to.meta.layout = 'auth';
                            next('/login');
                        }
                    }).catch(error => {
                        this.logout();
                        to.meta.layout = 'auth';
                        next('/login');
                    });
                }
            }).catch(error => {
                this.logout();
                to.meta.layout = 'auth';
                next('/login');
            })
        }
    }

    verifyEmail(email, token) {
        const data = {
            email: email,
            token: token
        }

        return axios.post(store.state.api_url + 'auth/verify-email', data)
    }

    verifyDevice(user_id, pincode) {
        return new Promise((resolve, reject) => {
            const data = {
                user_id: user_id,
                pincode: pincode
            }

            axios.post(store.state.api_url + 'auth/verify-device', data).then(response => {
                if (response.data.user) {
                    store.commit("setUser", response.data.user);
                    // store.commit("setAccessToken", response.data.accessToken);
                    // store.commit("setRefreshToken", response.data.refreshToken);
                }
                resolve(true);
            }).catch(error => {
                reject(error);
            })
        });
    }

    getDeviceInfo() {
        const deviceDetector = new DeviceDetector();
        const device = deviceDetector.parse(navigator.userAgent);
        return device;
    }

    getNetworkInfo() {
        return new Promise((resolve, reject) => {
            axios.get('https://ipinfo.io/json?token=cfb8d2f240beb6', {
                withCredentials: false
            }).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        });
    }
}

export default new AuthService();